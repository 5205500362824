export default {
    weekdays: [
        { label: 'Selecione', value: '' },
        { label: 'Domingo', value: 'Domingo' },
        { label: 'Segunda', value: 'Segunda' },
        { label: 'Terça', value: 'Terça' },
        { label: 'Quarta', value: 'Quarta' },
        { label: 'Quinta', value: 'Quinta' },
        { label: 'Sexta', value: 'Sexta' },
        { label: 'Sábado', value: 'Sábado' }
    ],
    typesAPN: [
        { label: 'Uma foto', value: 1 },
        //{ label: 'Duas fotos', value: 2 },
        //{ label: 'Foto da Cidade', value: 3 },
        { label: 'Padrão', value: 4 }
    ],
    typesB2B: [
        { label: 'Uma foto', value: 1 },
        { label: 'Duas fotos', value: 2 },
        { label: 'Padrão', value: 3 }
    ],
    typesRec: [
        //{ label: 'Sem foto', value: 'A' },
        { label: 'Com foto', value: 'B' },
    ],
    modelsRec: [
        { label: 'Black Diamond', value: 'safira' },
        { label: 'Imperial', value: 'ruby' },
        { label: 'Chanceller', value: 'emerald' },
        { label: 'Crown', value: 'diamond' },
        { label: 'Ambassador', value: 'diamond-blue' },
    ],
    getLabelModelRec: function(value)
    {
        return this.modelsRec.find(item => value === item.value )
    }
}